.cardTitle {
        margin:100px 0 20px 0;
        text-align:center;
    }

.cardContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin:20px 0;
        
    .card {        
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width:33%;
        // background-color: #eb2121;
        background-color: #fff;
        padding:20px;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
        color:rgb(58, 58, 58);
        margin-bottom:20px;
        text-align: center;
        border:1px solid rgba(0,0,0,.15);

        .icon {
            background:#eb2121;
            border-radius: 50%;
            width:50px;
            height:50px;
            position: relative;
            margin-right: 20px;

            svg {
                fill:white;
                position: absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%)
            }
        }
    }
}

@media screen and (max-width:768px){
    .cardContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .card {
        width:100% !important;
    }

    .content {
        width:60%;
    }

    .cardTitle {
        margin:20px 0 20px 0;
        text-align:center;
    }
}
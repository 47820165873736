.WerkIntro {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:0 80px;

    .WerkSvgContainer {
        padding:20px;

        img {
            width:40%;
            margin:0 auto;
            display:block;
        }
    }

    .WerkintroText {
        padding: 20px;

        h3 {
            text-align:center
        }
    }
}

.heistelling {
    width:200px;
    height: auto;
    display:block;
    margin: 0 auto;
}


@media screen and (max-width:768px){
    .svgIntro {
        flex-direction: column;

        .svgContainer img {
            margin:20px auto;
            display:block;    
        }
    }

    .WerkIntro {
        padding:0px;

        .WerkSvgContainer img {
            width:100%;
        }
    }
}
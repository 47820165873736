

.contactLanding {
    padding: 20px 80px;

    .contactWelcome {
        text-align:left;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 50px 0;

        .welcomeText {
            width:60%;
            align-self:flex-start;
        }

        .socials {
            width:40%;

            img {
                width:40px;
                height: auto;
                margin-left:auto;
                display:block;                
                
            }
        }
    }

    .formContainer {
        margin: 10px 0; 

        form {
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            label {
                align-self: flex-start;
            }

            input,
            textarea {
                width:100%;
                padding:10px;
                margin: 0 0 10px 0;
                border:1px solid rgb(196, 196, 196);

                &:focus {
                    outline:1px solid #ED1B24;
                }
            }
            input[type="submit"]{
                width:100%;
                padding:15px;
                background:#ED1B24;
                color:white;
                border:none;
                margin:20px 0;
                transition:background 0.2s ease;

                &:hover {
                    background:#7F7F7F;
                }
            }            
        }
    }
}

.succesMail {
    border: 1px solid #ed1b24;
    width: 100%;
    padding: 10px;
}

@media screen and (max-width:768px){
    .contactLanding {
        padding: 20px;
    }
}
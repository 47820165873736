nav {
    height:10vh;
    background-color:#fff;
    padding:0 200px;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid rgb(192, 192, 192);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .logo {
        color:rgb(58, 58, 58);
        font-weight: 700;
        font-size:28px;
        font-family: 'Lobster', cursive;

        img {
            width:60px;
            height:auto
        }
    }
}

.nav-links {
    display:flex;
    justify-content: flex-end;
    align-items: center;
    width:70%;
    height:100%;
    margin-left:auto;

     li div {
        text-decoration: none;
        color:rgb(58, 58, 58);
        font-size:16px;
        border-bottom:3px solid transparent;
        margin-right:50px;

        &:hover {
            border-bottom:3px solid #ED1B24;
        }
    }
}



@media screen and (max-width: 1200px) {

    .line {
        width:30px;
        height: 3px;
        background: rgb(58, 58, 58);
        margin:5px;
    }

    nav {
        position: relative;
        height: 10vh;
        background-color: #fff;
        padding: 0 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    }

    .hamburger {
        position: absolute;
        cursor: pointer;
        right:5%;
        top:50%;
        transform:translate(-5%, -50%);
        z-index:2;
        overflow: hidden;

        div {
            transition: all 0.3s ease;
        }
    }

    .nav-links {
        position:fixed;
        background: rgb(199, 199, 199);
        color:rgb(58, 58, 58);
        height:100vh;
        width:100%;
        flex-direction: column;
        justify-content: center;
        clip-path: circle(100px at 90% -10%);
        -webkit-clip-path: circle(100px at 90% -10%);
        transition: all 0.35s ease-out;
        pointer-events: none;
        top: -10%;
        left: 0;

        li {
            margin-bottom:30px;

            div {
                color:rgb(58, 58, 58);
                margin-right:0;
            }
        }

    }

    .nav-links.open{
            clip-path: circle(1200px at 90% -10%);
            -webkit-clip-path: circle(1200px at 90% -10%);
            pointer-events: all;
            top: 0;
            left: 0;
            transition: all 0.35s ease-out;
            z-index: 1;
    }

    .toggle .line1 {
        transform:rotate(-45deg) translate(-5px, 6px)
    }

    .toggle .line2 {
        opacity: 0;
    }

    .toggle .line3 {
        transform:rotate(45deg) translate(-5px, -6px)
    }

}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }



.landing {
    display:flex;
    justify-content: flex-end;
    align-items: center;  
    padding:0 0px;  
    background: url('../../assets/images/55.jpg');
}


.hero-svg {
    padding: 20px;
    width:50%;

    img {
        width:70%;
        margin:auto;
        display:block;
    }
}

.intro {
    width:50%;
    margin:30px;
    padding:20px;
    display:flex;
    flex-direction: column;
    align-items: center;
    background:white;

    &-logo img {
        width:250px;
    }

    h2 {
        margin: 20px 0;
        text-align: center;
    }

    h3 {
       text-align:center;
    }
}


@media screen and (max-width:768px){

    .landing {
        padding:0px;
        background:#7F7F7F;
    }
    .hero-image{
        display:none;
        visibility: hidden;
    }

    .intro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding:0 20px;
        width:100%;
        
        h2 {
            text-align: center;
            margin: 20px 0;
            
        }
    }

    .landing {
        flex-direction:column;
    }

    .container {
        padding-top:0;
    }

    .hero-svg {
        padding: 0 20px;
        width:100%;
    
        img {
            width:100%;
        }
    }
}



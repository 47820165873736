.row {
    display: flex;
    flex-direction: row;
    justify-content:space-around;      
    flex-wrap: wrap;

    img {
        width:300px;
        height:300px;
    }
}

.row > div {
    margin-bottom: 60px;
    cursor: pointer;
}

.h3-projecten {
    text-align: center;
    margin-bottom:20px;
}

#myBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
  }
  
  #myBtn:hover {
    background-color: #555; /* Add a dark-grey background on hover */
  }

@media screen and (max-width:768px) {
    .h3-projecten {
        padding-top:20px;
    }
}